function innovationInit() {
    const innovation = document.querySelector('.section-innovation');

    if (innovation) {
        const innovationContainer = innovation.querySelector('.container.container-skinny');
        const innovationBtn = innovation.querySelector('.btn-inverted .btn.btn-outline-white')
        let innovationHeight = innovation.offsetHeight;
        innovationHeight += innovationHeight * 3

        gsap.timeline({
            scrollTrigger: {
                // normalizeScroll: true,
                trigger: innovation,
                pin: true,
                pinSpacing: true,
                markers: false,
                start: 'top top',
                end: `+=${innovationHeight}`,
                scrub: 1
            },
        })
            // .set(innovationBtn, { borderColor: 'transparent' })
            .set(innovation, { backgroundColor: '#000', color: '#fff' })
            .to({}, { duration: 2 }) // like a pause
            .to('.text-collapse-1', { yPercent: -100 })
            .set('.btn-inverted', { className: 'btn-inverted active' }, ">")
            // .set(innovationContainer, { onComplete: () => { innovationContainer.classList.add('text-black') } }, ">")
            .to(innovation, { backgroundColor: '#fff', color: '#000' }, "<")
            .to(innovationBtn, { color: '#000', borderColor: '#000' }, "<")
            .from('.text-collapse-2', { yPercent: 100 }, "<")
            .to({}, { duration: 2 }); // like a pause
    }
}